/* src/App.css */
.App {
  background-color: #f5e0ba;
  color: #7d4c3c;
  
  /* Fill until the end of html */
  height: 100%; 
  min-height: 100vh;
}

/* Banner Section */
.App-header {
  /* background-image: '../assets/c4.png'; */
  background-image: url('./assets/c4.png');
  background-size: cover;
  background-position: center;
  
  height: 340px;
  display: flex;
}

.App-header h1 {
  margin-top: 0px;
  font-family: 'Georgia', serif;
  font-size: 3rem;
  text-shadow: 2px 2px 2px #0000008f;

  margin: auto; /* Center the text */

  /* Add left/right padding */
  padding-left: 20px;
  padding-right: 20px;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center everything in the column */
}

.flex-row {
  display: flex;
  align-items: center; /* Align items vertically centered */
  justify-content: space-between; /* Distribute space between children */
}


/* Description Section */
.description {
  margin-top: 20px;
}

.description p {
  margin: auto;
  max-width: 800px;
  /* font-family: 'Arial', sans-serif; */
  /* font-size: 1rem; */
  margin-bottom: 9px;

  /* Justify text */
  text-align: justify;

  /* Add left/right padding */
  padding-left: 20px;
  padding-right: 20px;
}

h2 {
  flex-grow: 1;
}

.lyon-image {
  margin-left: 10px;
  width: 30px;
  height: auto;
}

/* Croissant Ranking Section */
.ranking {
  margin-top: 40px;
  margin: auto;
  max-width: 600px;
}

/* List styles */
.croissant-list {
  list-style-type: none;
  /* padding-left: 0; */

  /* Add left/right padding */
  padding-left: 25px;
  padding-right: 25px;
}

/* Each croissant item */
.croissant-item {
  margin-bottom: 20px;
}

/* Flexbox row for Rank and Name */
.croissant-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.rank {
  font-weight: bold;
  font-size: larger;
  margin-right: 10px; /* Space between rank and name */
}

.croissant-item span,
.croissant-item strong {
  font-family: 'Arial', sans-serif;
}

.croissant-item a {
  text-decoration: none;
  color: #695844;
}

/* Separator line */
.separator {
  border: none;
  border-top: 1px solid #ccc; /* Light gray line */
  margin-top: 10px;
}

/* All buttons */
button {
  /* background-color: #7d4c3c; */
  background-color: #7d4c3c00;
  border-color: #5a372b;
  border: 1px solid;
  color: #7d4c3c;
  /* border: none; */
  padding: 5px 10px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 1rem;
  margin-left: 10px;
}

button:hover {
  background-color: #5a372b;
}

/* Dialog Box */
.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #d4b87b;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 10px;
  max-width: 90%;
  width: 400px;
}

.dialog-content h3 {
  margin-top: 0;
  font-family: 'Georgia', serif;
  font-size: 1.5rem;
  color: #7d4c3c;
}

.dialog-content p {
  font-family: 'Arial', sans-serif;
  color: #7d4c3c;
  text-align: justify;
}

/* .dialog-content button {
  background-color: #7d4c3c;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-family: 'Arial', sans-serif;
  font-size: 1rem;
  margin-top: 10px;
}

.dialog-content button:hover {
  background-color: #5a372b;
} */

/* Tabs */
.tabs {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* .tabs button {
  background-color: #7d4c3c;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-family: 'Arial', sans-serif;
  font-size: 1rem;
  margin: 0 10px;
}

.tabs button:hover {
  background-color: #5a372b;
} */

/* Croissant Item Button */
/* .croissant-item button {
  background-color: #7d4c3c;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 20px;
  cursor: pointer;
  font-family: 'Arial', sans-serif;
  font-size: 0.9rem;
  margin-top: 5px;
}

.croissant-item button:hover {
  background-color: #5a372b;
} */